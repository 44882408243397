import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

import { Button, Space } from 'antd';

import { Modal } from './styles';

export default function ModalComponent(props) {
  const [visible, setVisible] = [props.visible, props.setVisible];
  const processing = !props.processing ? false : props.processing;
  const disable = !props.disabled ? false : props.disabled;

  const { ...rest } = props;

  const [modalState, setModalState] = useState({
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
  });

  const draggleRef = React.createRef();

  function onCancel(e) {
    setVisible(false);
  };

  function onStart(event, uiData) {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setModalState({
      ...modalState,
      bounds: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      },
    });
  };

  const { bounds, disabled } = modalState;

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setModalState({ ...modalState, disabled: false });
            }
          }}
          onMouseOut={() => {
            setModalState({ ...modalState, disabled: true });
          }}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          {props.title}
        </div>
      }
      visible={visible}
      onOk={props.onOk ? props.onOk : () => { setVisible(false) }}
      okText={props.okText}
      onCancel={props.onCancel ? props.onCancel : () => { setVisible(false) }}
      cancelText={props.cancelText}
      modalRender={modal => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      maskClosable={props.maskClosable}
      width={!props.width ? 500 : props.width}
      footer={[
        <Space style={{ padding: 8 }}>
          {props.onOk && (
            <Button loading={processing} disabled={disable} type="primary" onClick={props.onOk ? props.onOk : () => { setVisible(false) }}>{!props.okText ? 'Salvar' : props.okText}</Button>
          )}
          <Button className='type-default' type="default" onClick={props.onCancel ? props.onCancel : () => { setVisible(false) }}>{!props.cancelText ? 'Fechar' : props.cancelText}</Button>
        </Space>
      ]}
      {...rest}
    >
      {props.children}
    </Modal>
  )
}

ModalComponent.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  okText: PropTypes.string,
  onOk: PropTypes.func,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  maskClosable: PropTypes.bool,
  width: PropTypes.number
};

ModalComponent.DefaultLayout = {
  visible: false,
  onOk: undefined,
  onCancel: undefined
}
